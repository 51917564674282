<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>SR Number</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect
                        v-model="selectedCode"
                        :base-url="`${this.$store.state.outbound.supplierReturn.baseUrlPath}/codes?status=${tab}`"
                        label="code" track-by="code" @selected="(val) => {
                            this.selectedCode = val
                            this.$store.dispatch('outbound/supplierReturn/updateSr', val)
                        }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Supplier Code</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect v-model="selectedSupplier" base-url="/api/wms/v1/master/suppliers" label="name" track-by="name" @selected="(val) => {
                            this.selectedSupplier = val
                            this.$store.dispatch('outbound/supplierReturn/updateSupplier', val)
                        }"></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Return Date</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <DateRangePicker v-model="rangeDateValue" @rangeValue="(val) => { this.rangeDateValue = val; this.$store.dispatch('outbound/supplierReturn/updateDate', val) }" />
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw" :status="tab" :sr-id="(this.selectedCode) ? this.selectedCode.id : 0"
                    :range-date="rangeDateValue" :supplier-id="(this.selectedSupplier) ? this.selectedSupplier.id : 0">
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import CustomMSelect from "./CustomMultiSelect.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import Table from "./Table.vue";
export default {
    components: {
        CustomMSelect,
        Table,
        DateRangePicker,
    },
    props: {
        tab: {
            type: String
        }
    },
    data() {
        return {
            draw: 0,
            rangeDateValue: this.$store.state.outbound.supplierReturn.date,
            selectedCode: this.$store.state.outbound.supplierReturn.sr,
            selectedSupplier: this.$store.state.outbound.supplierReturn.supplier,
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
    }
}
</script>